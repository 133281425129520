import { IconButton, InputAdornment } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core'

type Props = {
  toggleType: React.MouseEventHandler<HTMLButtonElement>
}

const muiIconBtnStyles = {
  padding: 0,
  margin: 0,
}

export const EndAdornment = function ({ toggleType }: Props): JSX.Element {
  const theme = useTheme()

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        edge="end"
        onClick={toggleType}
        style={muiIconBtnStyles}
      >
        <FontAwesomeIcon
          icon={faEye}
          color={theme.palette.grey['A100']}
          flip="horizontal"
          size="sm"
        />
      </IconButton>
    </InputAdornment>
  )
}

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core'

import { PurposeData } from '../../../../../shared/types'

interface Props extends PurposeData {
  given: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const useStyles = makeStyles(function (theme) {
  return {
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: 4,
      backgroundColor: theme.palette.grey[50],
    },
    formControlLabel: {
      margin: 0,
      fontStyle: 'normal',
    },
    label: {
      flexGrow: 1,
    },
  }
})

export const ContentType = function (props: Props): JSX.Element {
  const classes = useStyles(props)
  const { id, label, description, given, onChange } = props

  return (
    <Grid item xs={12} sm={6}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            key={`consent-purpose-${id}`}
            control={<Switch checked={given} onChange={onChange} name={id} />}
            classes={{ root: classes.formControlLabel, label: classes.label }}
            label={
              <>
                <Typography variant="subtitle2">{label}</Typography>
                <Typography variant="caption">{description}</Typography>
              </>
            }
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    </Grid>
  )
}

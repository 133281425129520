import { Box, Toolbar as MuiToolBar, makeStyles } from '@material-ui/core'

type Props = {
  children: NonNullable<React.ReactNode>[]
}

const useStyles = makeStyles({
  toolBar: {
    justifyContent: 'space-between',
  },
  sameWidth: {
    width: '33.33%',
  },
})

export const Toolbar = function ({ children }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <MuiToolBar className={classes.toolBar}>
      <Box className={classes.sameWidth}>{children[0]}</Box>
      <Box className={classes.sameWidth} display="flex" justifyContent="center">
        {children[1]}
      </Box>
      <Box className={classes.sameWidth} display="flex" justifyContent="flex-end">
        {children[2]}
      </Box>
    </MuiToolBar>
  )
}

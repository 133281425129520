import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { useCallback, useMemo } from 'react'

import { Channel } from './channel'
import { Consent } from '../../../../shared/types'
import { ContentType } from './content-type'
import { Other } from './other'
import { contentIds } from './content-ids'
import { filter } from './filter'

type Props = {
  consent: Consent
  setConsent: Function
  showIntro: boolean
}

const useStyles = makeStyles(function (theme) {
  return {
    section: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    buttonsWrapper: {
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    parentGrid: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0px !important',
      },
    },
    childGrid: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px !important',
        marginTop: 0,
      },
    },
    box: {
      width: '100%',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: 4,
      backgroundColor: theme.palette.grey[50],
    },
  }
})

export const ConsentChoices = function ({ consent, setConsent, showIntro }: Props): JSX.Element {
  const classes = useStyles()

  const onChange = useCallback(
    function (event: React.ChangeEvent<HTMLInputElement>) {
      const newConsent = consent.map(function (purpose) {
        if (event.target.name === purpose.id) {
          purpose.given = event.target.checked
        }

        return purpose
      })

      setConsent(newConsent)
    },
    [consent, setConsent],
  )

  const content = useMemo(
    function () {
      return {
        contentTypes: consent.filter(filter(contentIds.contentTypes)),
        channels: consent.filter(filter(contentIds.channels)),
        others: consent.filter(filter(contentIds.others)),
      }
    },
    [consent],
  )

  return (
    <>
      <Grid item xs={12} className={`${showIntro ? classes.section : ''} ${classes.parentGrid}`}>
        <Typography variant="h3">Content</Typography>
        <Typography variant="caption" color="textSecondary">
          Please select what type of things you would like to hear more about
        </Typography>
        <Grid container xs={12} sm={12} spacing={3}>
          {content.contentTypes.map(function (purpose) {
            return <ContentType onChange={onChange} key={purpose.id} {...purpose} />
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} className={`${classes.section} ${classes.parentGrid}`}>
        <Typography variant="h3" gutterBottom>
          Channels
        </Typography>
        <Typography variant="caption" paragraph>
          Please select how you would like to hear from us
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} className={`${classes.childGrid}`}>
        <Grid container xs={12} sm={12} spacing={3}>
          {content.channels.map(function (purpose) {
            return <Channel onChange={onChange} key={purpose.id} {...purpose} />
          })}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} className={`${classes.section} ${classes.childGrid}`}>
        <Typography variant="h3" gutterBottom>
          Other types of communication
        </Typography>
      </Grid>

      <Grid container item xs={12} className={classes.box}>
        <Grid item xs={12} sm={9} className={`${classes.parentGrid}`}>
          <Typography variant="caption" paragraph>
            Please note we can send you messages related to your orders, services subscribed and
            updates relating to products or services you have purchased from us, or information
            about your account. This includes communications relating to Season Tickets, Ticket
            Sales and Official Membership benefits. If you explicitly would like to turn off these
            type of communication, it may limit your ability to be aware of these benefits.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} className={`${classes.childGrid}`}>
          <Box display="flex" className={classes.buttonsWrapper}>
            {content.others.map(function (purpose) {
              return <Other onChange={onChange} key={purpose.id} {...purpose} />
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

import { ConfirmationButtons, ContentWrapper, Layout } from '../components'
import { useCallback, useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Email } from '../components'
import { sendForgotPasswordCode } from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { useValidEmail } from '../hooks/useValidInputs'
import { useGTMEvent } from '../hooks/useGTMEvent'

const useStyles = makeStyles(function (theme) {
  return {
    requiredFieldAsterisk: {
      color: theme.palette.primary.main,
    },
  }
})

const ForgotPassword = (): JSX.Element => {
  const classes = useStyles()
  const {
    state: { email: initEmail },
    dispatch,
  } = useAuth()
  useGTMEvent('LFC SSO Forgot Password')
  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)

  const isInvalid = !emailIsValid || email.length === 0

  const sendCodeClicked = useCallback(async () => {
    setLoading(true)

    try {
      await sendForgotPasswordCode(dispatch, email, captchaResponse)
      setSuccess(true)
    } catch (err) {
      setError('Failed to send email, please try again later')
    }

    setLoading(false)
  }, [dispatch, email, captchaResponse])

  return (
    <Layout>
      <ContentWrapper
        columns={1}
        headerTitle="Forgotten password"
        headerChildren={
          <>
            Submit your email address below and we&apos;ll send you a link to verify your account
            and re-set your password
          </>
        }
        error={error}
        success={
          success
            ? {
                title: `Reset Password Link Sent to ${email}`,
                body: 'Check your email and follow the instructions to reset your password.',
                url: '/login',
              }
            : undefined
        }
      >
        <Box mb={3}>
          <Email
            defaultValue={email}
            emailIsValid={emailIsValid}
            setEmail={setEmail}
            classes={classes.requiredFieldAsterisk}
          />
        </Box>

        {/* Buttons */}
        <ConfirmationButtons
          onCaptchaChange={setCaptchaResponse}
          buttonProps={{
            onClick: sendCodeClicked,
            disabled: isInvalid,
            text: 'Submit',
          }}
          loading={loading}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default ForgotPassword

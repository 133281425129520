import { Link, makeStyles } from '@material-ui/core'

import oneX from '../../../../../assets/logo.png'
import threeX from '../../../../../assets/logo@3x.png'
import twoX from '../../../../../assets/logo@2x.png'

const useStyles = makeStyles({
  root: {
    width: 66,
    height: 30,
    objectFit: 'contain',
  },
})

export const Logo = function (): JSX.Element {
  const classes = useStyles()

  return (
    <Link href="https://www.liverpoolfc.com" title="Liverpool FC">
      <img
        src={oneX}
        srcSet={`${twoX} 2x,
        ${threeX} 3x`}
        className={classes.root}
      />
    </Link>
  )
}

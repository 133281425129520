import {
  AuthIsNotSignedIn,
  AuthIsSignedIn,
  AuthIsSignedUp,
  AuthProvider,
} from './context/authContext'
import { Redirect, Route, Switch, useLocation } from 'react-router'

import CssBaseline from '@material-ui/core/CssBaseline'
import ForgotPassword from './pages/ForgotPassword'
import { Helmet } from 'react-helmet-async'
import Profile from './pages/Profile'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'
import SignIn from './pages/SignIn'
import { Success } from './pages/Success'
import { ThemeProvider } from '@material-ui/core/styles'
import { UpdatePassword } from './pages/UpdatePassword'
import { theme } from './style'
import env from './shared/env'

const getSearch = () => {
  return useLocation().search
}

const PublicRoute = () => (
  <Switch>
    <Route path="/login" component={SignIn} exact />
    <Route path="/join" component={Register} exact />
    <Route path="/forgot-password" component={ForgotPassword} exact />
    <Route path="/reset-password" component={ResetPassword} exact />
    <Route path="/">
      <Redirect
        to={{
          pathname: '/login',
          search: getSearch(),
        }}
      />
    </Route>
    <Route>
      <Redirect
        to={{
          pathname: '/',
          search: getSearch(),
        }}
      />
    </Route>
  </Switch>
)

const MemberRoute = () => (
  <Switch>
    <Route path="/profile" component={Profile} exact />
    <Route path="/update-password" component={UpdatePassword} exact />
    <Route path="/success/:event" component={Success} exact />
    <Route path="/join">
      <Redirect
        to={{
          pathname: '/success/join',
          search: getSearch(),
        }}
      />
    </Route>
    <Route path="/login">
      <Redirect
        to={{
          pathname: '/success/login',
          search: getSearch(),
        }}
      />
    </Route>
    <Route path="/">
      <Redirect
        to={{
          pathname: '/profile',
          search: getSearch(),
        }}
      />
    </Route>
    <Route>
      <Redirect
        to={{
          pathname: '/',
          search: getSearch(),
        }}
      />
    </Route>
  </Switch>
)

const SignedUpRoute = () => (
  <Switch>
    <Route path="/login" component={SignIn} exact />
    <Route path="/join" exact>
      <Redirect
        to={{
          pathname: '/success/join',
          search: getSearch(),
        }}
      />
    </Route>
    <Route path="/success/:event" component={Success} exact />
    <Route path="/forgot-password" component={ForgotPassword} exact />
    <Route path="/reset-password" component={ResetPassword} exact />
    <Route path="/">
      <Redirect
        to={{
          pathname: '/login',
          search: getSearch(),
        }}
      />
    </Route>
    <Route>
      <Redirect
        to={{
          pathname: '/',
          search: getSearch(),
        }}
      />
    </Route>
  </Switch>
)

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />

    <Helmet>
      <title>{env.RAZZLE_NAME}</title>
    </Helmet>

    <AuthProvider>
      <AuthIsSignedIn>
        <MemberRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <PublicRoute />
      </AuthIsNotSignedIn>
      <AuthIsSignedUp>
        <SignedUpRoute />
      </AuthIsSignedUp>
    </AuthProvider>
  </ThemeProvider>
)

export default App

import { Box, makeStyles } from '@material-ui/core'

import { Error } from './error'
import { Header } from './header'
import { Props } from './props'
import { Success } from './success'
import { desktopWidth } from './desktop-width'
import { mobilePadding } from './mobile-padding'
import { mobileWidth } from './mobile-width'

const captchaWidth = 304

const useStyles = makeStyles(function (theme) {
  return {
    box: {
      [theme.breakpoints.down('xs')]: {
        paddingRight: mobilePadding,
        paddingLeft: mobilePadding,
        width: mobileWidth(captchaWidth),
      },
      [theme.breakpoints.up('sm')]: {
        width: desktopWidth(captchaWidth),
      },
    },
  }
})

export const ContentWrapper = function (props: Props): JSX.Element {
  const classes = useStyles(props)
  const { headerTitle, headerChildren, success, error, children } = props

  return (
    <>
      <Header title={headerTitle} fullWidth={props.fullWidth}>
        {headerChildren}
      </Header>

      {success && <Success {...success} />}

      {error && <Error error={error} />}

      <Box pt={8} pb={16} mx="auto" className={classes.box}>
        {children}
      </Box>
    </>
  )
}

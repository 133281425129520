import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@material-ui/core'
import { useTheme } from '@material-ui/core'

type Props = {
  valid?: boolean
}

export const ValidityIcon = function ({ valid }: Props): JSX.Element {
  const theme = useTheme()

  return (
    <InputAdornment position="end">
      <FontAwesomeIcon
        icon={valid ? faCheckCircle : faExclamationCircle}
        color={valid ? theme.palette.success.main : theme.palette.error.main}
        size="lg"
      />
    </InputAdornment>
  )
}

import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@material-ui/core'

import { PurposeData } from '../../../../../shared/types'

interface Props extends PurposeData {
  given: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const Other = function (props: Props): JSX.Element {
  const { id, given, onChange } = props

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          key={`consent-purpose-${id}`}
          control={<Switch checked={given} onChange={onChange} name={id} />}
          label={undefined}
        />
      </FormGroup>
    </FormControl>
  )
}

import { OneTrustPurposeId } from '../../../../shared/types'

// contentTypes
const { Products, News, Foundation, Partners } = OneTrustPurposeId

// channels
const { Email, Mail, SMS, Telephone } = OneTrustPurposeId

// others
const { LegitimateInterest } = OneTrustPurposeId

export const contentIds = {
  contentTypes: [Products, News, Foundation, Partners],
  channels: [Email, Mail, SMS, Telephone],
  others: [LegitimateInterest],
}

import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import env from '../../../../shared/env'

type Props = {
  onChange: (response: string | null) => void
  loading: boolean
}

export const Captcha = function ({ loading, onChange }: Props): JSX.Element {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [hasBeenLoading, setHasBeenLoading] = useState(false)

  // Track whether we have seen a loading state
  if (loading && !hasBeenLoading) {
    setHasBeenLoading(true)
  }
  // Loading finished but component is still active, indicating form submission error
  // Reset ReCaptcha challenge
  if (!loading && hasBeenLoading) {
    setHasBeenLoading(false)
    recaptchaRef.current?.reset()
  }

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={String(env.RAZZLE_RECAPTCHA_SITEKEY)}
      onChange={onChange}
    />
  )
}

import { CircularProgress, Button as MuiButton, makeStyles } from '@material-ui/core'

type Props = {
  buttonProps: {
    disabled: boolean
    onClick: React.MouseEventHandler<HTMLButtonElement>
    text: string
  }
  captchaOk: boolean
  loading: boolean
}

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export const Button = function ({ buttonProps, captchaOk, loading }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <>
      <MuiButton
        onClick={buttonProps.onClick}
        disabled={buttonProps.disabled || !captchaOk || loading}
        color="primary"
        variant="contained"
        fullWidth
      >
        {buttonProps.text}
      </MuiButton>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </>
  )
}

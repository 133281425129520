import { Button, Hidden, makeStyles } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../context/authContext'
import { parse } from 'tldjs'
import { useRedirect } from '../../../../../hooks/useRedirect'

const useStyles = makeStyles(function (theme) {
  return {
    button: {
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        fontSize: '50%',
        '& span': {
          justifyContent: 'flex-start',
        },
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '85%',
      },
    },
    former: {
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 0,
        left: 24,
        width: 35,
      },
    },
    latter: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 12,
        left: 23,
        lineHeight: 1.2,
      },
    },
  }
})

export const BackButton = function (): JSX.Element {
  const classes = useStyles()

  const {
    state: { originUrl },
  } = useAuth()

  const originUrlHostname = parse(originUrl).hostname
  const currentHostname = parse(window.location.origin).hostname
  const [handleRedirect, redirectLabel] = useRedirect(undefined, false)

  return (
    <>
      {originUrlHostname !== currentHostname && (
        <Button
          color="inherit"
          startIcon={<FontAwesomeIcon icon={faAngleLeft} />}
          className={classes.button}
          onClick={() => handleRedirect()}
          title={'Back' + (redirectLabel ? ` to ${redirectLabel}` : '')}
        >
          {redirectLabel ? (
            <>
              <span className={classes.former}>Back to</span>
              <Hidden xsDown implementation="css">
                <span>&nbsp;</span>
              </Hidden>
              <span className={classes.latter}>{redirectLabel}</span>
            </>
          ) : (
            <span className={classes.former}>Back</span>
          )}
        </Button>
      )}
    </>
  )
}

import * as yup from 'yup'

export const emailSchema = yup.string().email().lowercase().required()

export const namePartSchema = yup
  .string()
  .trim()
  .min(1, `This is a required field`)
  .max(30)
  .required()

export const codeSchema = yup.object().shape({
  code: yup.string().min(6).required(),
})

// Regex taken from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
// But made initial `+` sign optional
const phoneRegex = /^\+?(?:[0-9] ?){6,14}[0-9]$/
export const phoneSchema = yup
  .string()
  .matches(phoneRegex, { message: 'Invalid phone number', excludeEmptyString: true })

export const dataElementsSchema = yup
  .object()
  .shape({
    Nickname: yup.string(),
    'First Name': namePartSchema,
    'Last Name': namePartSchema,
    'Date of Birth': yup.string(),
    'Email Address': emailSchema,
    'LFC Country': yup.string(),
    'LFC State': yup.string(),
    Gender: yup.string(),
    Telephone: phoneSchema,
  })
  .required()

// MembershipId schema
export const membershipIdSchema = yup
  .string()
  .max(14)
  .matches(phoneRegex, { message: 'Invalid membership number', excludeEmptyString: true })

import { BaseTextFieldProps, TextField as MuiTextField } from '@material-ui/core'

import { useCallback } from 'react'

export interface TextFieldProps extends BaseTextFieldProps {
  label: string
  setValue: Function
  value: string | undefined
  InputProps?: object
  inputLabelClasses?: string
  handleOnChange?: (value: string) => void // custom onChange event handler prop
  handleOnBlur?: (value: string) => void // custom onBlur event handler prop
}

export const TextField = function ({
  label,
  setValue,
  value,
  disabled,
  handleOnChange,
  handleOnBlur,
  ...others
}: TextFieldProps): JSX.Element {
  const onChange = useCallback(
    (event) => {
      setValue(event.target.value)
    },
    [setValue, value],
  )

  return (
    <MuiTextField
      id={label.replace(/\s/g, '')}
      label={label}
      value={value}
      onChange={handleOnChange ? (event) => handleOnChange(event.target.value) : onChange}
      onBlur={handleOnBlur ? (event) => handleOnBlur(event.target.value) : undefined}
      variant="outlined"
      fullWidth
      disabled={disabled}
      {...others}
    />
  )
}

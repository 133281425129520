import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import { DATE_TIME_FORMAT_UI } from '../../../shared/util'
import DateFnsUtils from '@date-io/date-fns'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'

type Props = {
  value: ParsableDate
  onChange: (date: MaterialUiPickersDate) => void
  disabled?: boolean
  classes?: string
}

export const DateOfBirth = function ({ value, onChange, disabled, classes }: Props): JSX.Element {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableFuture
        required
        label="Date of birth"
        id="Date-of-birth"
        inputVariant="outlined"
        value={value}
        onChange={onChange}
        format={DATE_TIME_FORMAT_UI}
        size="small"
        fullWidth
        InputAdornmentProps={{
          style: {
            display: 'none',
          },
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            asterisk: classes,
          },
        }}
        maxDateMessage="Date of birth cannot be in the future"
        minDateMessage="Date of birth cannot be that far in the past"
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  )
}

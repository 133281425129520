import { Box, makeStyles } from '@material-ui/core'

type Props = {
  children: NonNullable<React.ReactNode>
  className: string
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      background: theme.palette.grey[100],
    },
  }
})

export const Well = function ({ children, className }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <Box p={2} className={`${classes.root} ${className}`}>
      {children}
    </Box>
  )
}

import { Alert } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import parse from 'html-react-parser'

type Props = {
  error: string
}

export const Error = function ({ error }: Props) {
  return (
    <>
      <Alert severity="warning">
        <Typography variant="subtitle1" align="center">
          {parse(error)}
        </Typography>
      </Alert>
    </>
  )
}

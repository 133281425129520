import { Code, Email, Password } from '../components'
import { ConfirmationButtons, ContentWrapper, Layout } from '../components'
import { useCallback, useEffect, useState } from 'react'
import { useValidCode, useValidEmail, useValidPassword } from '../hooks/useValidInputs'

import { Box } from '@material-ui/core'
import { confirmForgotNewPassword } from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { useGTMEvent } from '../hooks/useGTMEvent'
import { useResetPasswordQuery } from '../hooks/useQuery'

const ResetPassword = (): JSX.Element => {
  const { dispatch } = useAuth()

  const [error, setError] = useState('')
  const [params, paramsError] = useResetPasswordQuery()
  useEffect(() => {
    if (paramsError) {
      setError(paramsError)
    }
  }, [paramsError])

  useGTMEvent('LFC SSO Reset Password')

  const { email, emailIsValid } = useValidEmail(params.email)
  const { code, codeIsValid } = useValidCode(params.code)
  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)
 
  const isInvalid =
    !codeIsValid ||
    code.length === 0 ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const resetPassword = useCallback(async () => {
    if (!(email && code)) return

    setLoading(true)

    try {
      await confirmForgotNewPassword(dispatch, email, code, password, captchaResponse)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }

    setLoading(false)
  }, [code, dispatch, email, password, captchaResponse])

  return (
    <Layout>
      <ContentWrapper
        columns={1}
        headerTitle="Update Password"
        headerChildren=""
        error={error}
        success={
          success
            ? {
                title: 'Your new password has been set',
                body: 'You will be redirected to log in 5 sec.',
                url: '/login',
              }
            : undefined
        }
      >
        <Box mb={3}>
          <Email defaultValue={email} disabled emailIsValid={emailIsValid} />
        </Box>
        <Box mb={3}>
          <Code defaultValue={code} disabled codeIsValid={codeIsValid} />
        </Box>
        <Box mb={3}>
          <Password
            label="New Password"
            passwordIsValid={passwordIsValid}
            setPassword={setPassword}
            error={passwordError}
            isNew
          />
        </Box>
        <Box mb={6}>
          <Password
            label="Re-enter New Password"
            passwordIsValid={passwordConfirmIsValid}
            setPassword={setPasswordConfirm}
            isNew
          />
        </Box>

        {/* Buttons */}
        <ConfirmationButtons
          onCaptchaChange={setCaptchaResponse}
          buttonProps={{
            onClick: resetPassword,
            disabled: isInvalid,
            text: 'Update Password',
          }}
          loading={loading}
        />
      </ContentWrapper>
    </Layout>
  )
}
export default ResetPassword

import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  Select as MuiSelect,
} from '@material-ui/core'

import { useCallback } from 'react'

type Value = {
  code: string
  name: string
}

type Props = {
  values: Value[]
  setValue: Function
  label: string
  name: string
  code: string | undefined
  defaultValue?: string
  classes?: string
  required?: boolean
  disabled?: boolean
}

/**
 * Workaround for labels not covering outlined select controls, creating a
 * strikethrough effect.  Should be fixed in next major version of material-ui
 * (v5), which should render this obsolete in the future.
 *
 * See https://github.com/mui-org/material-ui/issues/16954#issuecomment-580034129
 */
const useStyles = makeStyles(() => {
  return {
    outlined: {
      backgroundColor: '#ffffff',
      paddingLeft: 2,
      paddingRight: 2,
    },
  }
})

export const Select = function ({
  values,
  setValue,
  label,
  name,
  code,
  defaultValue,
  classes,
  required = false,
  disabled = false,
}: Props): JSX.Element {
  const id = `${name}-input`

  const onChange = useCallback(
    function (event: React.ChangeEvent<{ name?: string; value: unknown }>) {
      setValue(event.target.value as string)
    },
    [setValue],
  )

  const classesFix = useStyles()
  const showError = required && !code && !defaultValue

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        htmlFor={id}
        shrink={true}
        required={required}
        classes={{
          asterisk: classes,
          ...classesFix,
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        native
        required={required}
        onChange={onChange}
        label={label}
        value={code || defaultValue || undefined}
        inputProps={{
          name,
          id,
        }}
        error={showError}
        disabled={disabled}
      >
        <option key={`${name}-none`} value="none" selected disabled>
          Select {label}
        </option>

        {values.map(function (value) {
          return (
            <option key={`${name}-${value.code}`} value={value.code}>
              {value.name}
            </option>
          )
        })}
      </MuiSelect>
      {showError && <FormHelperText className={classes}>This is a required field</FormHelperText>}
    </FormControl>
  )
}

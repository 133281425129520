import { useState } from 'react'

import { EndAdornment } from './end-adornment'
import { TextField } from '@material-ui/core'

type Props = {
  label: string
  passwordIsValid: boolean
  error?: string[]
  isNew?: boolean
  setPassword: (_: string) => void
  classes?: string
}

export const Password = ({
  label,
  passwordIsValid,
  setPassword,
  isNew,
  error,
  classes,
}: Props): JSX.Element => {
  const [type, setType] = useState<'password' | 'text'>('password')

  const toggleType = () => {
    const newType = type === 'password' ? 'text' : 'password'
    setType(newType)
  }

  const errorJSX = error
    ?.filter((e: string) => {
      return e !== ''
    })
    .map((e: string, i: number): JSX.Element => {
      return (
        <span key={i}>
          {e}
          <br />
        </span>
      )
    })

  return (
    <TextField
      fullWidth
      required
      variant="outlined"
      type={type}
      autoComplete={isNew ? 'new-password' : 'current-password'}
      id={label.replace(/\s/g, '')}
      label={label}
      helperText={!passwordIsValid ? <> {errorJSX} </> : <></>}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value)
      }}
      InputProps={
        isNew
          ? {}
          : {
              endAdornment: <EndAdornment toggleType={toggleType} />,
            }
      }
      InputLabelProps={{
        shrink: true,
        classes: {
          asterisk: classes,
        },
      }}
    />
  )
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { DATE_TIME_FORMAT_ONETRUST, DEFAULT_DOB_ONETRUST, FALLBACK_DATE_ISO } from '../shared/util'
import { useEffect, useState } from 'react'

import { DataSubjectPreferences } from '../shared/types'
import { parse } from 'date-fns'

export const useUserPreferences = (userPreferences?: DataSubjectPreferences) => {
  const initFirstName = userPreferences ? userPreferences.dataElements['First Name'] : ''
  const initLastName = userPreferences ? userPreferences.dataElements['Last Name'] : ''
  const initNickname = userPreferences ? userPreferences.dataElements['Nickname'] : ''
  const initEmail = userPreferences ? userPreferences.dataElements['Email Address'] : ''
  const initTelephone = userPreferences ? userPreferences.dataElements['Telephone'] : ''

  const initDob = userPreferences
    ? userPreferences.dataElements['Date of Birth'] || DEFAULT_DOB_ONETRUST
    : DEFAULT_DOB_ONETRUST
  const initCountry = userPreferences ? userPreferences.dataElements['LFC Country'] : ''
  const initState = userPreferences ? userPreferences.dataElements['LFC State'] : ''
  const initGender = userPreferences ? userPreferences.dataElements.Gender : ''
  const initConsent = userPreferences ? userPreferences.consent : []

  const [firstName, setFirstName] = useState(initFirstName)
  const [lastName, setLastName] = useState(initLastName)
  const [nickname, setNickname] = useState(initNickname)
  const [email, setEmail] = useState(initEmail)
  const [telephone, setTelephone] = useState(initTelephone)
  const [dob, setDob] = useState<Date | null>(
    parse(initDob, DATE_TIME_FORMAT_ONETRUST, new Date(FALLBACK_DATE_ISO)),
  )
  const [gender, setGender] = useState(initGender)
  const [country, setCountry] = useState(initCountry)
  const [state, setState] = useState(initState)
  const [consent, setConsent] = useState(initConsent)
  const metaElements = userPreferences ? userPreferences.metaElements : {}

  useEffect(() => {
    if (!userPreferences) return
    const newDob = userPreferences.dataElements['Date of Birth'] || DEFAULT_DOB_ONETRUST
    setFirstName(userPreferences.dataElements['First Name'])
    setLastName(userPreferences.dataElements['Last Name'])
    setNickname(userPreferences.dataElements['Nickname'])
    setEmail(userPreferences.dataElements['Email Address'])
    setTelephone(userPreferences.dataElements['Telephone'])
    setDob(parse(newDob, DATE_TIME_FORMAT_ONETRUST, new Date(FALLBACK_DATE_ISO)))
    setGender(userPreferences.dataElements.Gender)
    setCountry(userPreferences.dataElements['LFC Country'])
    setState(userPreferences.dataElements['LFC State'])
    setConsent(userPreferences.consent)
  }, [userPreferences])

  useEffect(() => {
    setFirstName(firstName)
    setLastName(lastName)
    setNickname(nickname)
    setEmail(email)
    setTelephone(telephone)
    setDob(dob)
    setGender(gender)
    setCountry(country)
    setState(state)
    setConsent(consent)
  }, [firstName, lastName, email, dob, gender, country, state, consent, nickname, telephone])

  return {
    firstName,
    lastName,
    nickname,
    email,
    telephone,
    dob,
    gender,
    country,
    state,
    consent,
    metaElements,
    setFirstName,
    setLastName,
    setNickname,
    setEmail,
    setTelephone,
    setDob,
    setGender,
    setCountry,
    setState,
    setConsent,
  }
}

import { Box, Typography, makeStyles } from '@material-ui/core'

type Props = {
  title: string
  children: NonNullable<React.ReactNode>
  fullWidth?: boolean
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      background: theme.palette.grey[100],
      paddingTop: theme.spacing(9),
      [theme.breakpoints.up('xs')]: {
        paddingBottom: function (fullWidth: boolean) {
          return fullWidth && fullWidth === true ? theme.spacing(3) : theme.spacing(11)
        },
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: function (fullWidth: boolean) {
          return fullWidth && fullWidth === true ? '0%' : '10%'
        },
        paddingRight: function (fullWidth: boolean) {
          return fullWidth && fullWidth === true ? '0%' : '10%'
        },
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: function (fullWidth: boolean) {
          return fullWidth && fullWidth === true ? '0%' : '25%'
        },
        paddingRight: function (fullWidth: boolean) {
          return fullWidth && fullWidth === true ? '0%' : '25%'
        },
      },
    },
  }
})

export const Header = function ({ title, children, fullWidth }: Props): JSX.Element {
  const classes = useStyles(fullWidth)

  return (
    <Box className={classes.root}>
      <Typography variant="h1" align="center" color="primary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {children}
      </Typography>
    </Box>
  )
}

import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import states from 'states-us'

countries.registerLocale(enLocale)

interface Select {
  readonly code: string
  readonly name: string
}

export const getCountries = (language = 'en'): Select[] => {
  const countryObj = countries.getNames(language, { select: 'official' })
  const sortedCountries: Select[] = Object.entries(countryObj)
    .sort(([, lhs], [, rhs]) => lhs.localeCompare(rhs))
    .map(([k, v]) => ({ code: k, name: v }))
  return sortedCountries
}

export const getUsStates = (): Select[] =>
  states.map((s) => ({ code: s.abbreviation, name: s.name }))

export const getGenders = (): Select[] => {
  return [
    {
      code: '',
      name: 'Not Specified',
    },
    {
      name: 'Male',
      code: 'M',
    },
    {
      code: 'F',
      name: 'Female',
    },
  ]
}

import { Link as MuiLink, Typography, TypographyVariant } from '@material-ui/core'

import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  linkProps?: {
    url: string
    text: string
  }
  altText: string
  variant?: TypographyVariant
}

export const Link = function ({ linkProps, altText, variant }: Props): JSX.Element {
  const history = useHistory()
  const location = useLocation()

  const linkClick = useCallback(
    function () {
      if (linkProps !== undefined) {
        history.push(`${linkProps.url}${location.search}`)
      }
    },
    [history, location, linkProps],
  )

  return (
    <Typography variant={variant || 'subtitle1'} align="center">
      {altText}&nbsp;
      {linkProps && (
        <MuiLink href="#" onClick={linkClick}>
          {linkProps.text}
        </MuiLink>
      )}
    </Typography>
  )
}

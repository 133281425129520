import { AuthIsSignedIn } from '../../../../context/authContext'
import { BackButton } from './back-button'
import { LanguageSelector } from './language-selector'
import { Logo } from './logo'
import { AppBar as MuiAppBar } from '@material-ui/core'
import { Profile } from './profile'
import { Toolbar } from './tool-bar'

export const AppBar = function (): JSX.Element {
  const currentURI = window.location.href
  const isPOQNativeApp = currentURI.includes('header=false')
  return (
    <MuiAppBar position="static" elevation={0}>
      <Toolbar>
        <BackButton />
        {!isPOQNativeApp && <Logo />}
        <>
          <AuthIsSignedIn>
            <Profile />
          </AuthIsSignedIn>
          {/* <LanguageSelector /> */}
        </>
      </Toolbar>
    </MuiAppBar>
  )
}

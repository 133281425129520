import { ConfirmationButtons, ContentWrapper, Layout } from '../components'
import { useCallback, useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Password } from '../components'
import { changePassword } from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { useValidPassword } from '../hooks/useValidInputs'
import { useGTMEvent } from '../hooks/useGTMEvent'

const useStyles = makeStyles(function (theme) {
  return {
    requiredFieldAsterisk: {
      color: theme.palette.primary.main,
    },
  }
})

export const UpdatePassword = (): JSX.Element => {
  const classes = useStyles()
  const { dispatch } = useAuth()

  useGTMEvent('LFC SSO Update Password')

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
    passwordError: oldPasswordError,
  } = useValidPassword('')

  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)

  const isInvalidPassword =
    oldPassword.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)

  const changePasswordClicked = useCallback(async () => {
    setLoading(true)

    try {
      await changePassword(dispatch, oldPassword, password, captchaResponse)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }

    setLoading(false)
  }, [dispatch, oldPassword, password, captchaResponse])

  return (
    <Layout>
      <ContentWrapper
        columns={1}
        headerTitle="Update Password"
        headerChildren=""
        error={error}
        success={
          success
            ? {
                title: 'Your new password has been set',
                body: 'You will be redirected to your profile in 5 sec.',
                url: '/profile',
              }
            : undefined
        }
      >
        <Box mb={3}>
          <Password
            label="Old Password"
            passwordIsValid={oldPassword.length !== 0}
            setPassword={setOldPassword}
            classes={classes.requiredFieldAsterisk}
          />
        </Box>
        <Box mb={3}>
          <Password
            label="New Password"
            passwordIsValid={passwordIsValid}
            setPassword={setPassword}
            error={passwordError}
            isNew
            classes={classes.requiredFieldAsterisk}
          />
        </Box>
        <Box mb={6}>
          <Password
            label="Re-enter New Password"
            passwordIsValid={passwordConfirmIsValid}
            setPassword={setPasswordConfirm}
            isNew
            classes={classes.requiredFieldAsterisk}
          />
        </Box>

        {/* Buttons */}
        <ConfirmationButtons
          onCaptchaChange={setCaptchaResponse}
          buttonProps={{
            onClick: changePasswordClicked,
            disabled: isInvalidPassword,
            text: 'Update Password',
          }}
          loading={loading}
        />
      </ContentWrapper>
    </Layout>
  )
}

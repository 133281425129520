/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useState, useEffect, useCallback } from 'react'
import { CollectionPointData, Consent } from '../shared/types'
import apiClient from '../lib/apiClient'

interface OneTrustResponse {
  success: boolean
  payload: CollectionPointData
}

export enum OneTrustEndpoints {
  Registration = '/registration_consent_data',
}

interface OneTrustCache {
  Registration?: CollectionPointData
}

export const useOneTrust = (type: keyof OneTrustCache) => {
  const cache = useRef<OneTrustCache>({})
  const [consent, setConsent] = useState<Consent>([])

  const fetchData = useCallback(async () => {
    if (cache.current.Registration) {
      const payload = cache.current.Registration
      setConsent(payload.purposes.map((p) => ({ ...p, given: false })))
    } else {
      try {
        const response = await apiClient.get<OneTrustResponse>(OneTrustEndpoints[type])
        if (!response.data?.success && response.data?.payload) {
          throw new Error('Failed to get OneTrust response')
        }
        const { payload } = response.data
        cache.current.Registration = payload
        setConsent(payload.purposes.map((p) => ({ ...p, given: false })))
      } catch (err) {
        console.error(err)
      }
    }
  }, [type])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { consent, setConsent }
}

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useCallback, useMemo, useState } from 'react'

import { Consent } from '../../../shared/types'
import { ConsentChoices } from './consent-choices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

type Props = {
  consent: Consent
  setConsent: Function
  interactionRequired?: boolean
  showIntro?: boolean
}

const useStyles = makeStyles(function (theme) {
  return {
    buttonsWrapper: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    radioGroup: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: -11,
      },
    },
    label: {
      paddingRight: theme.spacing(1),
      borderColor: theme.palette.primary.main,
      borderWidth: theme.spacing(0.5),
      borderStyle: 'solid',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('xs')]: {
        width: '49%',
        marginLeft: 0,
        marginRight: 0,
      },
    },
    firstLabel: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '2%',
      },
    },
    textGrid: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px !important',
      },
      marginTop: theme.spacing(2),
    },
    btnLabelCapitalize: {
      textTransform: 'capitalize',
    },
  }
})

export const MarketingConsent = function ({
  consent,
  setConsent,
  interactionRequired = false,
  showIntro = true,
}: Props): JSX.Element {
  const classes = useStyles()
  const [showChoices, setShowChoices] = useState(!showIntro)

  const toggleChoices = useCallback(
    function () {
      setShowChoices(function (prevShowChoices) {
        return !prevShowChoices
      })
    },
    [setShowChoices],
  )

  const enabled = useMemo(
    function () {
      return (
        !interactionRequired &&
        consent.length > 0 &&
        consent.every(function (purpose) {
          return purpose.given === true
        })
      )
    },
    [consent, interactionRequired],
  )

  const disabled = useMemo(
    function () {
      return (
        !interactionRequired &&
        consent.length > 0 &&
        consent.every(function (purpose) {
          return purpose.given === false
        })
      )
    },
    [consent, interactionRequired],
  )

  const onChange = useCallback(
    function (event: React.ChangeEvent<HTMLInputElement>) {
      const newConsent = consent.map(function (purpose) {
        purpose.given = event.target.name === 'enabled' ? true : false

        return purpose
      })

      setConsent(newConsent)
    },
    [consent, setConsent],
  )

  return (
    <Grid item container xs={12}>
      {showIntro && (
        <>
          <Grid item xs={12}>
            <Typography variant="button" gutterBottom>
              Get more from LFC?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.textGrid}>
            <Typography variant="body2" gutterBottom>
              Do you want to hear about our products, competitions, new signings, tickets, club news
              and partner offers?
            </Typography>
            <Typography variant="caption" color="textSecondary" paragraph>
              We send offers and news on behalf of our partners but do not share your information
              directly with them.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" className={classes.buttonsWrapper}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio color="primary" checked={enabled} onChange={onChange} name="enabled" />
                    }
                    className={`${classes.label} ${classes.firstLabel}`}
                    label={<Typography variant="subtitle1">Yes</Typography>}
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="primary"
                        checked={disabled}
                        onChange={onChange}
                        name="disabled"
                      />
                    }
                    className={classes.label}
                    label={<Typography variant="subtitle1">No</Typography>}
                  />
                </RadioGroup>
              </FormControl>
              <Button
                variant={showChoices ? 'contained' : 'outlined'}
                color="primary"
                endIcon={<FontAwesomeIcon icon={faAngleDown} />}
                onClick={toggleChoices}
                className={classes.btnLabelCapitalize}
              >
                Manage
              </Button>
            </Box>
          </Grid>
        </>
      )}
      {showChoices && consent.length > 0 && (
        <ConsentChoices consent={consent} setConsent={setConsent} showIntro={showIntro} />
      )}

      <Grid item xs={12} className={classes.textGrid}>
        <Typography variant="caption" color="textSecondary">
          You can acess our preference centre at any time to opt out of communications and control
          how [we] use your data. By registering to join, you confirm that you have read and accept
          Liverpool Football Club{' '}
          <Link href="https://www.liverpoolfc.com/legal/terms-and-conditions" color="inherit">
            Terms and Conditions
          </Link>{' '}
          and have read the club{' '}
          <Link href="https://www.liverpoolfc.com/legal/privacy-policy" color="inherit">
            Privacy Policy
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  )
}

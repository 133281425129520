import { TextField, TextFieldProps } from '../text-field'
import { useCallback, useState } from 'react'

import { ValidityIcon } from '../validity-icon'

type RequiredFieldProps = TextFieldProps & { message?: string | null }

export const RequiredField = function ({
  label,
  setValue,
  value,
  inputLabelClasses,
  message,
  disabled,
}: RequiredFieldProps): JSX.Element {
  const [internalValue, setInternalValue] = useState(undefined)

  const showError = message != null || internalValue === ''

  const extendSetValue = useCallback(
    (value) => {
      setValue(value)
      setInternalValue(value)
    },
    [setValue, setInternalValue, value],
  )

  return (
    <TextField
      required
      label={label}
      id={label.replace(/\s/g, '')}
      setValue={extendSetValue}
      value={value}
      error={showError}
      helperText={showError ? message ?? 'This is a required field' : ''}
      InputProps={
        internalValue === undefined
          ? {}
          : {
              endAdornment: <ValidityIcon valid={!showError} />,
            }
      }
      InputLabelProps={{
        shrink: true,
        classes: {
          asterisk: inputLabelClasses,
        },
      }}
      disabled={disabled}
    />
  )
}

import { Action, ActionType, authReducer } from './reducers'
import { AuthState, AuthStatus } from './stateTypes'
import React, { Dispatch, useCallback, useContext, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router'

import { getDomain } from 'tldjs'
import { signInSession } from './dispatchers'
import { getOriginChannel, LFC_TLD } from '../shared/util'
import { Channel, SubChannel } from '../shared/types'

export interface AuthContextType {
  state: AuthState
  dispatch: Dispatch<Action>
}

const defaultState: AuthState = {
  authStatus: AuthStatus.Loading,
  email: '',
  originUrl: '',
  originChannel: undefined,
}

const AuthContext = React.createContext<AuthContextType>({
  state: defaultState,
  dispatch: () => null,
})

const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(authReducer, defaultState)
  const { authStatus, originUrl } = state

  // TODO: Handle longer loading state here by returning provider with loading spinner.
  // return <></>

  const getSessionInfo = useCallback(async () => {
    try {
      if (authStatus === AuthStatus.Loading) {
        await signInSession(dispatch)
      }
    } catch (err) {
      dispatch({ type: ActionType.SignOut })
    }
  }, [authStatus])

  const queryParams = new URLSearchParams(useLocation().search)

  useEffect(() => {
    if (originUrl === '') {
      getSessionInfo()
      let newOriginUrl = window.location.origin
      const referrer = queryParams.get('redirect_uri')
      const ecal = queryParams.get('ecal') === 'true'
      let originChannel: Channel | SubChannel | undefined

      if (referrer && getDomain(referrer) === LFC_TLD) {
        try {
          newOriginUrl = decodeURIComponent(referrer)
          originChannel = getOriginChannel(newOriginUrl, ecal)
        } catch {
          console.warn(
            `Incorrect redirect_uri supplied: ${referrer}; falling back to ${newOriginUrl}`,
          )
        }
      }

      dispatch({
        type: ActionType.SetOrigin,
        payload: { originUrl: newOriginUrl, originChannel },
      })
    }
  }, [getSessionInfo, originUrl, queryParams])

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>
}

export const AuthIsSignedIn = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { state } = useContext(AuthContext)

  return <>{state.authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { state } = useContext(AuthContext)

  return <>{state.authStatus === AuthStatus.SignedOut ? children : null}</>
}

export const AuthIsSignedUp = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { state } = useContext(AuthContext)

  return <>{state.authStatus === AuthStatus.SignedUp ? children : null}</>
}

export { AuthProvider }

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

import { Typography, makeStyles } from '@material-ui/core'

type Props = {
  children: NonNullable<React.ReactNode>[]
  title: string
}

const useStyles = makeStyles({
  ul: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
})

export const List = function ({ children, title }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="caption" component="ul" className={classes.ul}>
        {children.map(function (child, index) {
          return <li key={`list-${title}-li-${index}`}>{child}</li>
        })}
      </Typography>
    </>
  )
}

import '@fontsource/roboto-condensed/400.css'
import '@fontsource/roboto-condensed/700.css'

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import { SwitchProps } from '@material-ui/core'
// Needed for TS definitions of MUI Lab components
import themeAugmentation from '@material-ui/lab/themeAugmentation'

const colors = {
  red: '#dc0714',
  green: '#31b5aa',
  black: '#4a4a49',
  grey: '#7c7c7b',
  lightgrey: '#C9CED0',
  yellow: '#ffe144',
}

const pixelFontSizes = {
  9: '0.5625rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  15: '0.9375rem',
  16: '1rem',
  31: '1.9375rem',
  42: '2.625rem',
}

const fontWeights = {
  normal: 400,
  bold: 700,
}

const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: `'Roboto Condensed', sans-serif`,
      fontWeightRegular: fontWeights.normal,
      fontWeightBold: fontWeights.bold,
      h1: {
        fontSize: pixelFontSizes[42],
        fontWeight: fontWeights.normal,
      },
      h2: {
        fontSize: pixelFontSizes[31],
        fontWeight: fontWeights.bold,
        textTransform: 'uppercase',
      },
      h3: {
        fontSize: pixelFontSizes[12],
        fontWeight: fontWeights.bold,
        textTransform: 'uppercase',
      },
      body1: {
        fontSize: pixelFontSizes[15],
        fontWeight: fontWeights.normal,
      },
      body2: {
        fontSize: pixelFontSizes[12],
        fontWeight: fontWeights.normal,
        fontStyle: 'normal',
      },
      subtitle1: {
        fontSize: pixelFontSizes[15],
        fontWeight: fontWeights.bold,
      },
      subtitle2: {
        fontSize: pixelFontSizes[10],
        fontWeight: fontWeights.bold,
        fontStyle: 'normal',
      },
      caption: {
        fontSize: pixelFontSizes[10],
        fontWeight: fontWeights.normal,
      },
      button: {
        fontSize: pixelFontSizes[16],
        fontWeight: fontWeights.bold,
      },
    },
    palette: {
      primary: {
        main: colors.red,
      },
      success: {
        main: colors.green,
      },
      error: {
        main: colors.red,
      },
      warning: {
        main: colors.yellow,
      },
      background: {
        default: colors.red,
        paper: 'white',
      },
      text: {
        primary: colors.black,
        secondary: colors.grey,
      },
    },
    props: {
      MuiLink: {
        underline: 'always',
      },
      MuiTextField: {
        size: 'small',
      },
      MuiFormControl: {
        size: 'small',
      },
      MuiAlert: {
        icon: <></>,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          },
        },
      },
      MuiLink: {
        root: {
          fontWeight: fontWeights.bold,
          fontStyle: 'italic',
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.black,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: colors.lightgrey,
        },
      },
      MuiFormControlLabel: {
        root: {
          fontStyle: 'italic',
          fontSize: pixelFontSizes[14],
        },
      },
      MuiButtonBase: {
        root: {
          fontStyle: 'italic',
          fontSize: pixelFontSizes[14],
        },
      },
    },
  }),
)

theme.overrides = {
  ...theme.overrides,
  MuiContainer: {
    root: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
  // Fix: Remove unnecessary x-padding in grid in mobile (causes horizontal scroll otherwise)
  MuiGrid: {
    container: {
      '&$spacing-xs-5': {
        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
          marginLeft: '0 !important',
          marginRight: '0 !important',
        },
        '& $item': {
          [theme.breakpoints.down('xs')]: {
            padding: '20px 0',
          },
        },
      },
      '&$spacing-xs-2': {
        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
          marginLeft: '0 !important',
          marginRight: '0 !important',
        },
        '& $item': {
          [theme.breakpoints.down('xs')]: {
            padding: '8px 0',
          },
        },
      },
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
    },
    contained: {
      textTransform: 'none',
      fontStyle: 'italic',
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      borderWidth: theme.spacing(0.5),
      '&:hover': {
        borderWidth: theme.spacing(0.5),
      },
    },
  },
  MuiRadio: {
    colorPrimary: {
      color: theme.palette.primary.main,
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 0,
      justifyContent: 'center',
    },
    standardWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    standardSuccess: {
      backgroundColor: theme.palette.success.main,
    },
  },
  MuiMenuItem: {
    root: {
      color: theme.palette.primary.main,
    },
  },
  MuiSwitch: {
    thumb: {
      boxShadow: 'none',
      backgroundColor: function ({ checked, color }: SwitchProps) {
        // console.log('checked, color', checked, color)
        return checked
          ? theme.palette[color === 'secondary' ? 'secondary' : 'primary'].main
          : theme.palette.grey[500]
      },
    },
    track: {
      backgroundColor: theme.palette.grey[400],
    },
  },
}

export { theme }

import { Container, Paper, makeStyles } from '@material-ui/core'

import { AppBar } from './app-bar'

type Props = {
  children: NonNullable<React.ReactNode>
}

const useStyles = makeStyles(function (theme) {
  return {
    paper: {
      minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    },
  }
})

export const Layout = function ({ children }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <Container maxWidth="xl">
      <AppBar />
      <Paper elevation={0} square className={classes.paper}>
        {children}
      </Paper>
    </Container>
  )
}

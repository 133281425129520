import { TextField } from '@material-ui/core'
import { ValidityIcon } from '../validity-icon'

type Props = {
  defaultValue?: string
  emailIsValid?: boolean
  setEmail?: (_: string) => void
  disabled?: boolean
  classes?: string
}

export const Email = ({
  defaultValue = '',
  emailIsValid,
  setEmail,
  disabled,
  classes,
}: Props): JSX.Element => {
  const showError = defaultValue !== '' && !emailIsValid

  return (
    <TextField
      fullWidth
      required
      disabled={disabled}
      variant="outlined"
      label="E-mail"
      id="E-mail"
      helperText={showError ? 'Invalid email' : ''}
      type="email"
      autoComplete="username"
      error={showError}
      value={defaultValue}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typeof setEmail === 'function') {
          setEmail(evt.target.value)
        }
      }}
      InputProps={
        defaultValue === ''
          ? {}
          : {
              endAdornment: <ValidityIcon valid={emailIsValid} />,
            }
      }
      InputLabelProps={{
        shrink: true,
        classes: {
          asterisk: classes,
        },
      }}
    />
  )
}

import { AuthState, AuthStatus } from './stateTypes'

import { DataSubjectPreferences, SubChannel } from '../shared/types'
import { Channel } from '../shared/types'

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum ActionType {
  Load = 'LOAD',
  SignIn = 'SIGN_IN',
  SignInSession = 'SIGN_IN_SESSION',
  SignOut = 'SIGN_OUT',
  SignUp = 'SIGN_UP',
  SignUpResendConfirmation = 'SIGN_UP_RESEND_CONFIRMATION',
  SetUserPreferences = 'SET_USER_PREFERENCES',
  SetOrigin = 'SET_ORIGIN',
  ForgotPasswordSendCode = 'FORGOT_PASSWORD_SEND_CODE',
  ForgotPasswordSetNew = 'FORGOT_PASSWORD_SET_NEW',
  ChangePassword = 'CHANGE_PASSWORD',
}

interface Payload {
  [ActionType.Load]: undefined
  [ActionType.SignInSession]: undefined
  [ActionType.SignIn]: undefined
  [ActionType.SignOut]: undefined
  [ActionType.SignUp]: {
    email: string
  }
  [ActionType.SignUpResendConfirmation]: undefined
  [ActionType.ForgotPasswordSendCode]: {
    email: string
  }
  [ActionType.ForgotPasswordSetNew]: {
    email: string
  }
  [ActionType.ChangePassword]: undefined
  [ActionType.SetUserPreferences]: {
    userPreferences: DataSubjectPreferences
  }
  [ActionType.SetOrigin]: {
    originUrl: string
    originChannel: Channel | SubChannel | undefined
  }
}

export type Action = ActionMap<Payload>[keyof ActionMap<Payload>]

export const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ActionType.SignIn:
    case ActionType.SignInSession: {
      return {
        ...state,
        authStatus: AuthStatus.SignedIn,
      }
    }
    case ActionType.SignOut: {
      return {
        ...state,
        authStatus: AuthStatus.SignedOut,
      }
    }
    case ActionType.SignUp: {
      return {
        ...state,
        authStatus: AuthStatus.SignedUp,
        email: action.payload.email,
      }
    }
    case ActionType.SetUserPreferences: {
      return {
        ...state,
        userPreferences: action.payload.userPreferences,
      }
    }
    case ActionType.SetOrigin: {
      return {
        ...state,
        originUrl: action.payload.originUrl,
        originChannel: action.payload.originChannel,
      }
    }
    case ActionType.ForgotPasswordSetNew:
    case ActionType.ForgotPasswordSendCode: {
      return {
        ...state,
        email: action.payload.email,
      }
    }
    default: {
      return state
    }
  }
}

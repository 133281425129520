import { LinearProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
})

export const Progress = function (): JSX.Element {
  const classes = useStyles()
  return <LinearProgress className={classes.root} />
}

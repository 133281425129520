import { useLocation } from 'react-router-dom'

export interface ResetPasswordQuery {
  email: string,
  code: string,
}

const defeaultResetPasswordQuery: ResetPasswordQuery = {
  email: '',
  code: '',
}

export const useResetPasswordQuery = (): [ResetPasswordQuery, string | null] => {
  const params = new URLSearchParams(useLocation().search)
  try {
    const authCode = params.get('auth_code')
    if (!authCode) {
      return [defeaultResetPasswordQuery, `Required 'auth_code' parameter missing`]
    }
    const decodedParams = JSON.parse(window.atob(authCode))
    if (!decodedParams) {
      return [defeaultResetPasswordQuery, `Tampered 'auth_code' parameter`]
    }
    const email = decodedParams.username
    if (!email) {
      return [defeaultResetPasswordQuery, `Required 'username' parameter missing`]
    }
    const code = params.get('code')
    if (!code) {
      return [defeaultResetPasswordQuery, `Required 'code' parameter missing`]
    }
    return [{ email, code }, null]
  } catch (err) {
    console.error(err)
    return [defeaultResetPasswordQuery, `One or more required parameters missing`]
  } 
} 

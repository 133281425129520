import axios from 'axios'
import { isDev, UUID_SSO_APP, LFC_TLD } from '../shared/util'
import env from '../shared/env'

const port = isDev ? `:${env.PORT}` : ''
const apiClient = axios.create({
  baseURL: `http${isDev ? '' : 's'}://account.${LFC_TLD}${port}/api/`,
  // timeout: isProd ? 3000 : 0,
  headers: {
    Authorization: `Bearer ${UUID_SSO_APP}`,
  },
  // Handle client errors (4xx) in regular promise flow
  validateStatus: (statusCode) => statusCode < 500,
})

export default apiClient

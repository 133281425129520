import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type GTMEvent = { event: string } & (
  | {
      pagePath: string
      pageTitle: string
    }
  | {
      eventCategory: string
      eventAction: string
      eventLabel: string
    }
)

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[]
  }
}

const pushGTMEvent = (event: GTMEvent): void => {
  if (typeof window === 'undefined') {
    return
  }

  if (!window.dataLayer) {
    window.dataLayer = []
  }

  window.dataLayer.push(event)
}

export const useGTMEvent = (pageTitle: string) => {
  const { pathname, search, hash } = useLocation()
  useEffect(
    () => pushGTMEvent({ event: 'lfcVPV', pagePath: `${pathname}${search}${hash}`, pageTitle }),
    [pathname, search, hash],
  )
  return pushGTMEvent
}

import { Link, Typography } from '@material-ui/core'

import { Alert } from '@material-ui/lab'
import { useRedirect } from '../../../../hooks/useRedirect'

type Props = {
  title: string
  body?: string
  url?: string
}

export const Success = function ({ title, body, url }: Props) {
  const [handleRedirect] = useRedirect(url)

  return (
    <Alert severity="success">
      <Typography variant="subtitle1" align="center">
        {title}
      </Typography>
      {body && (
        <Typography variant="body1" align="center" gutterBottom>
          {body}
        </Typography>
      )}
      {handleRedirect && (
        <Typography variant="body2" align="center">
          <Link href="#" onClick={handleRedirect} color="textPrimary">
            Click here to continue
          </Link>
        </Typography>
      )}
    </Alert>
  )
}

import { Box, Button, Typography, useTheme } from '@material-ui/core'

import { Layout } from '../components'
import { RouteComponentProps } from 'react-router'
import { useRedirect } from '../hooks/useRedirect'

type Params = {
  event: 'login' | 'join'
}

const content = {
  login: {
    text: 'Login successful.',
  },
  join: {
    text: 'Your account has been registered.',
  },
}

export const Success = function ({ match }: RouteComponentProps<Params>): JSX.Element {
  const { text } = content[match.params.event]
  const theme = useTheme()

  // Don't redirect to origin if you just registered (which might be source channel)
  // but to the login page instead
  const redirectUrl = match.params.event === 'join' ? '/login' : undefined
  const [handleRedirect, redirectLabel] = useRedirect(
    redirectUrl,
    true,
    redirectUrl ? 'log in' : undefined,
  )

  return (
    <Layout>
      <Box
        width="100%"
        height={`calc(100vh - ${theme.spacing(10)}px)`}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={300}>
          <Typography variant="h1" color="primary" align="center" gutterBottom>
            Success!
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            {text} You will be redirected back {redirectLabel ? `to ${redirectLabel}` : ''} in 5
            sec.
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={handleRedirect}>
            Continue
          </Button>
        </Box>
      </Box>
    </Layout>
  )
}

import { TextField } from '@material-ui/core'

type Props = {
  defaultValue?: string
  disabled?: boolean
  codeIsValid?: boolean
  setCode?: (_: string) => void
}

export const Code = function ({
  defaultValue,
  disabled,
  codeIsValid,
  setCode,
}: Props): JSX.Element {
  return (
    <TextField
      fullWidth
      required
      value={defaultValue}
      disabled={disabled}
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      id="Code"
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (typeof setCode === 'function') {
          setCode(evt.target.value)
        }
      }}
    />
  )
}

import { Box, Typography, makeStyles, Grid } from '@material-ui/core'
import { desktopWidth } from '../../desktop-width'
import { mobileWidth } from '../../mobile-width'

type Props = {
  firstName: string | undefined
  lastName: string | undefined
  supporterID: string | undefined
}

const captchaWidth = 304

const useStyles = makeStyles({
  supporterContainer: {
    background: '#fff',
    paddingTop: '24px',
    paddingBottom: '24px',
    margin: '48px 0 24px 0',
    borderBottomColor: '#e0e0e0',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
  },
  supporterID: {
    backgroundColor: '#FAFAFA',
    margin: '0 2rem',
    padding: '0.5rem 0',
    textTransform: 'uppercase',
  },
})

export const SupporterInfo = function ({ firstName, lastName, supporterID }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.supporterContainer}>
        <Grid container justify="center" alignItems="center">
          <Grid item sm={3}>
            <Typography variant="h2" align="center">
              {firstName} {lastName}
            </Typography>
          </Grid>
          {supporterID && (
            <Grid item sm={3}>
              <Box className={classes.supporterID}>
                <Typography variant="subtitle2" color="textSecondary">
                  Supporter ID
                </Typography>
                <Typography variant="subtitle1">{supporterID}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { useCallback, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { signOut } from '../../../../../context/dispatchers'
import { useAuth } from '../../../../../context/authContext'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

export const Profile = function (): JSX.Element {
  const classes = useStyles()
  const history = useHistory()
  const {
    state: { userPreferences },
    dispatch,
  } = useAuth()

  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null)

  const handleOpen = useCallback(
    function (event: React.MouseEvent<HTMLButtonElement>) {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(
    function () {
      setAnchorEl(null)
    },
    [setAnchorEl],
  )

  const logout = useCallback(
    async function () {
      await signOut(dispatch)
      // TODO: show in UI (notification or screen) that user logged out!
      history.push('/')
    },
    [dispatch, history],
  )

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open user menu"
        className={classes.root}
        onClick={handleOpen}
      >
        <FontAwesomeIcon icon={faUserCircle} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

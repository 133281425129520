import { Box, Link, makeStyles, Typography } from '@material-ui/core'
import { ConfirmationButtons, ContentWrapper, Layout } from '../components'
import { Link as FormLink } from '../components/form-components/confirmation-buttons/link'
import { Email, Password } from '../components'
import { useCallback, useEffect, useState } from 'react'
import { useValidEmail, useValidPassword } from '../hooks/useValidInputs'

import { signIn } from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useGTMEvent } from '../hooks/useGTMEvent'

const useStyles = makeStyles(function (theme) {
  return {
    requiredFieldAsterisk: {
      color: theme.palette.primary.main,
    },
  }
})

const SignIn = (): JSX.Element => {
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const {
    state: { email: initEmail },
    dispatch,
  } = useAuth()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const { password, setPassword } = useValidPassword('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)
  const pushGTMEvent = useGTMEvent('LFC SSO Login')
  useEffect(
    () =>
      pushGTMEvent({
        event: 'openedlogin',
        eventCategory: 'Account',
        eventAction: 'Login',
        eventLabel: 'Starts',
      }),
    [location, pushGTMEvent],
  )

  const isInvalid = !emailIsValid || email.length === 0 || password.length === 0

  const signInEvent = useCallback(async () => {
    setLoading(true)

    try {
      await signIn(dispatch, email, password, true, captchaResponse)
      pushGTMEvent({
        event: 'successfullogin',
        eventCategory: 'Account',
        eventAction: 'Login',
        eventLabel: 'Completion',
      })
    } catch (err) {
      setError(err.message)
      pushGTMEvent({
        event: 'failedlogin',
        eventCategory: 'Account',
        eventAction: 'Login',
        eventLabel: 'Failed',
      })
    }

    setLoading(false)
  }, [dispatch, email, password, captchaResponse, pushGTMEvent])

  const forgotPassword = useCallback(
    function () {
      history.push(`/forgot-password${location.search}`)
    },
    [history, location],
  )

  return (
    <Layout>
      <ContentWrapper
        columns={1}
        headerTitle="Log In"
        headerChildren={
          <FormLink
            linkProps={{ url: '/join', text: 'Register Now' }}
            altText="No account? Unlock new, exciting and exclusive benefits all in one place. Join MyLFC, your FREE, Official LFC Account."
          />
        }
        error={error}
      >
        {/* Sign In Form */}
        <Box mb={5}>
          <Email
            defaultValue={email}
            emailIsValid={emailIsValid}
            setEmail={setEmail}
            classes={classes.requiredFieldAsterisk}
          />
        </Box>
        <Box mb={1}>
          <Password
            label="Password"
            passwordIsValid={true}
            setPassword={setPassword}
            classes={classes.requiredFieldAsterisk}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="body2" align="center">
            <Link href="#" onClick={forgotPassword}>
              Forgot password
            </Link>
          </Typography>
        </Box>

        {/* Buttons */}
        <ConfirmationButtons
          onCaptchaChange={setCaptchaResponse}
          buttonProps={{
            onClick: signInEvent,
            disabled: isInvalid,
            text: 'Login',
          }}
          linkProps={{
            url: '/join',
            text: 'Register Now',
          }}
          altText="No account?"
          loading={loading}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default SignIn

import { DataSubjectPreferences, SubChannel } from '../shared/types'
import { Channel } from '../shared/types'

export type IdToken = {
  token: string
  expiration: string
  issuedAt: string
}

export type AccessToken = IdToken

export enum AuthStatus {
  Loading = 'LOADING',
  SignedIn = 'SIGNED_IN',
  SignedOut = 'SIGNED_OUT',
  SignedUp = 'SIGNED_UP',
}

export interface AuthState {
  authStatus: AuthStatus
  userPreferences?: DataSubjectPreferences
  email: string // pre-login
  originUrl: string // where the user came from to SSO
  originChannel: Channel | SubChannel | undefined
}
